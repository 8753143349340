import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "../Auth/ConfigApi";

export const DiscussionsModuleApi = createApi({
  reducerPath: "Discussion",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Discussion"],
  endpoints: (builder) => ({
    getAllDiscussions: builder.query({
      query: (params) => ({
        url: `/discussion`,
        params,
      }),
      providesTags: ["Discussion"],
    }),

    getSingleDiscussion: builder.query({
      query: (params) => ({
        url: `/discussion/show/${params}`,
      }),
      providesTags: ["Discussion"],
    }),

    getTopRatedDiscussion: builder.query({
      query: () => ({
        url: `/discussion/top-rated`,
      }),
      providesTags: ["Discussion"],
    }),

    createDiscussions: builder.mutation({
      query: (params) => ({
        method: "POST",
        url: `/discussion/create`,
        body: params,
      }),
      extraOptions: { multipart: true },
      providesTags: ["Discussion"],
    }),

    updateDiscussions: builder.mutation({
      query: ({ params, id }) => ({
        method: "POST",
        url: `/discussion/update/${id}`,
        body: params,
      }),
      extraOptions: { multipart: true },
      providesTags: ["Discussion"],
    }),

    deleteDiscussions: builder.mutation({
      query: (params) => ({
        method: "POST",
        url: `/discussion/delete/${params}`,
      }),
      providesTags: ["Discussion"],
    }),

    likeDiscussion: builder.mutation({
      query: (params) => ({
        method: "POST",
        url: `/discussion/like/${params}`,
      }),
      providesTags: ["Discussion"],
    }),

    unlikeDiscussion: builder.mutation({
      query: (params) => ({
        method: "POST",
        url: `/discussion/unlike/${params}`,
      }),
      providesTags: ["Discussion"],
    }),

    addDiscussionComments: builder.mutation({
      query: (params) => ({
        method: "POST",
        url: `/discussion/comments/create`,
        body: params,
      }),
      extraOptions: { multipart: true },
      providesTags: ["Discussion"],
    }),

    removeDiscussionComments: builder.mutation({
      query: (params) => ({
        method: "POST",
        url: `/discussion/comments/delete/${params}`,
      }),
      providesTags: ["Discussion"],
    }),

    // getDiscussionComments: builder.query({
    //   query: (params) => ({
    //     url: `/discussion/comments/${params?.id}`,
    //     params,
    //   }),
    //   providesTags: ["Discussion"],
    // }),
    getDiscussionComments: builder.query({
      query: (params) => {
        const filteredParams = Object.fromEntries(
          Object.entries(params).filter(
            ([key, value]) => value !== null && value !== undefined
          )
        );

        return {
          url: `/discussion/comments/${params?.id}`,
          params: filteredParams,
        };
      },
      providesTags: ["Discussion"],
    }),

    getDiscussionCommentsTest: builder.query({
      query: (params) => {
        const filteredParams = Object.fromEntries(
          Object.entries(params).filter(
            ([key, value]) => value !== null && value !== undefined
          )
        );

        return {
          url: `/discussion/comments/${params?.id}`,
          params: filteredParams,
        };
      },
      providesTags: ["Discussion"],
    }),

    pinDiscussion: builder.mutation({
      query: (params) => ({
        method: "POST",
        url: `/discussion/pins/create/${params}`,
      }),
      providesTags: ["Discussion"],
    }),

    unPinDiscussion: builder.mutation({
      query: (params) => ({
        method: "POST",
        url: `/discussion/pins/delete/${params}`,
      }),
      providesTags: ["Discussion"],
    }),

    getPinnedDiscussion: builder.query({
      query: (params) => ({
        url: `/discussion/pins`,
        params,
      }),
      providesTags: ["Discussion"],
    }),

    likeComment: builder.mutation({
      query: (params) => ({
        method: "POST",
        url: `/discussion/comments/like/${params}`,
      }),
      providesTags: ["Discussion"],
    }),

    unLikeComment: builder.mutation({
      query: (params) => ({
        method: "POST",
        url: `/discussion/comments/unlike/${params}`,
      }),
      providesTags: ["Discussion"],
    }),
  }),
});

export const {
  useGetAllDiscussionsQuery,
  useGetSingleDiscussionQuery,
  useGetTopRatedDiscussionQuery,

  useCreateDiscussionsMutation,
  useDeleteDiscussionsMutation,
  useUpdateDiscussionsMutation,

  useLikeDiscussionMutation,
  useUnlikeDiscussionMutation,

  useAddDiscussionCommentsMutation,
  useRemoveDiscussionCommentsMutation,
  useGetDiscussionCommentsQuery,

  usePinDiscussionMutation,
  useUnPinDiscussionMutation,
  useGetPinnedDiscussionQuery,

  useLikeCommentMutation,
  useUnLikeCommentMutation,

  useLazyGetAllDiscussionsQuery,
  useLazyGetPinnedDiscussionQuery,
  useLazyGetSingleDiscussionQuery,
  useLazyGetDiscussionCommentsTestQuery,
  useLazyGetDiscussionCommentsQuery,
} = DiscussionsModuleApi;
