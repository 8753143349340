import React, { useEffect } from "react";
import { Pagination } from "@mantine/core";
const PaginationComp = ({ activePage, setPage, total }) => {
  useEffect(() => {
    if (total < activePage) {
      setPage(total);
    }
  }, [total, activePage, setPage]);

  const handlePageChange = (page) => {
    setPage(page);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <Pagination
      value={activePage || 1}
      onChange={handlePageChange}
      total={total}
      color="teal"
      className="mt-4 flex justify-center items-center"
    />
  );
};

export default PaginationComp;
