import React from "react";
import JobsCard from "./JobsCard";
import PaginationComp from "../../../Shared/PaginationComp";
import ListingNoData from "../../../Shared/No Data Found/ListingNoData";


export default function JobsSearchResult({data, activePage, setPage, total}) {
  return (
    <>
      <section className="mt-4">
          {data?.data?.data?.length > 0 ?  (
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 sm:gap-5 w-full">
              {data?.data?.data?.map((job) => 
                <div key={job?.id}> 
                  <JobsCard job={job} Swiper={false}/>
                </div>
              )}
            </div>
        ) : (
          <div className=" w-full col-span-3">
            <ListingNoData
              listingPage={"Jobs Data"}
            />
          </div>
        )}

        {/* Pagination */}
        <div className="w-full px-4">
            <div className="flex justify-center items-center p-4">
              {data?.data?.data?.length > 0 && (
                <PaginationComp
                  activePage={activePage}
                  setPage={setPage}
                  total={total}
                />
              )}
            </div>
          </div>
      </section>
    </>
  );
}
