import React from "react";
// import { useFollowProfileMutation } from "../../store/api/shared/API";
import { useAuth } from "../../Context/authContext";
import { useLocation, useNavigate } from "react-router-dom";

export default function ListingNoData({ listingPage, userID }) {
  const { user } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  // const [followProfile] = useFollowProfileMutation();

  return (
    <div className="flex justify-center items-start w-full">
      <div className="flex flex-col justify-center items-center">
        <h2 className="text-main font-title font-bold text-base text-center">
          No {listingPage} yet keep follow for updates
        </h2>
        {!user ? (
          <button
            type="button"
            className="text-white bg-main rounded-lg w-50 text-center py-2 mt-3 font-sans font-bold text-base"
            onClick={() => {
              navigate("/sign-in", { state: { from: location } });
            }}
          >
            Go to Signin
          </button>
        ) : (
          ""
          // <button
          //   className=" font-semibold font-paragarphFont bg-bgmain text-main w-40 h-auto p-2 rounded-full border-0 m-auto text-center flex justify-center item-center hover:bg-hoverColor hover:text-white"
          //   onClick={() => followProfile(userID)}
          // >
          //   Follow
          // </button>
        )}
      </div>
    </div>
  );
}
