import React from "react";
import { Container, Row } from "react-bootstrap";
import "./education.css";
import "../../Search/search.css";

export default function EducationHeader({ title }) {
  return (
    <>
      <section className="search__header">
        <Container fluid>
          <Row>
            <div className="search__container">
              <h1 className="search__header__title education__title">
                {title}
              </h1>
            </div>
          </Row>
        </Container>
      </section>
    </>
  );
}
