import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "../Auth/ConfigApi";

export const GroupModuleApi = createApi({
  reducerPath: "Groups",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Groups"],
  endpoints: (builder) => ({
    getAllGroups: builder.query({
      query: (params) => ({
        url: `/groups`,
        params,
      }),
      providesTags: ["Get_All_Groups"],
      keepUnusedDataFor: 0,
    }),

    getSingleGroups: builder.query({
      query: (params) => ({
        url: `/groups/show/${params}`,
      }),
      providesTags: ["Groups"],
    }),

    getSingleGroupDiscussions: builder.query({
      query: (params) => ({
        url: `/groups/show/${params}/discussions`,
      }),
      providesTags: ["Groups"],
    }),

    getGroupMembers: builder.query({
      query: (params) => ({
        url: `/groups/${params?.id}/members`,
        params,
      }),
      providesTags: ["Groups"],
    }),

    getMutualConnectionsMembers: builder.query({
      query: (params) => ({
        url: `/groups/${params?.id}/mutual-connections`,
        params,
      }),
      providesTags: ["Groups"],
    }),

    createGroup: builder.mutation({
      query: (params) => ({
        method: "POST",
        url: `/groups/create`,
        body: params,
      }),
      extraOptions: { multipart: true },
      providesTags: ["Groups"],
    }),

    editGroup: builder.mutation({
      query: ({ params, id }) => ({
        method: "POST",
        url: `/groups/update/${id}`,
        body: params,
      }),
      extraOptions: { multipart: true },
      providesTags: ["Groups"],
    }),

    deleteGroup: builder.mutation({
      query: (params) => ({
        method: "POST",
        url: `/groups/delete/${params}`,
      }),
      extraOptions: { multipart: true },
      providesTags: ["Groups"],
    }),

    getGroupRequests: builder.query({
      query: ({ id, params }) => ({
        url: `/groups/requests/${id}`,
        params,
      }),
      providesTags: ["Groups"],
    }),

    groupRequests: builder.mutation({
      query: (params) => ({
        method: "POST",
        url: `/groups/requests/handle`,
        body: params,
      }),
      extraOptions: { multipart: true },
      providesTags: ["Groups"],
    }),

    joinGroupRequest: builder.mutation({
      query: (params) => ({
        method: "POST",
        url: `/groups/join/${params}`,
      }),
      providesTags: ["Groups"],
    }),

    removeMemberFromGroup: builder.mutation({
      query: (params) => ({
        method: "POST",
        url: `/groups/kick`,
        body: params,
      }),
      extraOptions: { multipart: true },
      providesTags: ["Groups"],
    }),

    getRemoveGroupMemberRequests: builder.query({
      query: (params) => ({
        url: `/groups/${params?.id}/kick-requests`,
        params,
      }),
      providesTags: ["Groups"],
    }),

    getGroupAdmins: builder.query({
      query: ({ id, params }) => ({
        url: `/groups/admins/${id}`,
        params,
      }),
      providesTags: ["Groups"],
    }),

    addGroupAdmins: builder.mutation({
      query: (params) => ({
        method: "POST",
        url: `/groups/admins/add`,
        body: params,
      }),
      extraOptions: { multipart: true },
      providesTags: ["Groups"],
    }),

    removeGroupAdmins: builder.mutation({
      query: (params) => ({
        method: "POST",
        url: `/groups/admins/remove`,
        body: params,
      }),
      extraOptions: { multipart: true },
      providesTags: ["Groups"],
    }),
  }),
});

export const {
  useGetAllGroupsQuery,
  useGetSingleGroupsQuery,
  useGetSingleGroupDiscussionsQuery,
  useGetGroupMembersQuery,
  useGetMutualConnectionsMembersQuery,

  useCreateGroupMutation,
  useEditGroupMutation,
  useDeleteGroupMutation,

  useGetGroupRequestsQuery,
  useGroupRequestsMutation,
  useJoinGroupRequestMutation,
  useRemoveMemberFromGroupMutation,
  useGetRemoveGroupMemberRequestsQuery,

  useGetGroupAdminsQuery,
  useAddGroupAdminsMutation,
  useRemoveGroupAdminsMutation,

  useLazyGetAllGroupsQuery,
  useLazyGetSingleGroupsQuery,
  useLazyGetGroupRequestsQuery,
  useLazyGetMutualConnectionsMembersQuery,
  useLazyGetGroupMembersQuery,
  useLazyGetGroupAdminsQuery,
} = GroupModuleApi;
