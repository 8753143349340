import { Group, Tree } from "@mantine/core";
// import { IconChevronDown } from "@tabler/icons-react";
import React from "react";
import { useAuth } from "../Context/authContext";
import { useNavigate } from "react-router-dom";

const data = [
  {
    label: "My Account",
    value: "My Account",
    children: [
      {
        label: "Basic Profile",
        value: "My Account/Basic Profile",
        // path: true,
      },
      {
        label: "Edit My Profile",
        value: "My Account/Edit My Profile",
        path: true,
      },
      {
        label: "Upgrade Premium",
        value: "upgrade/premium",
        path: true,
        type: "premium",
        custom: true,
      },
      {
        label: "Transactions History",
        value: "transactions/history",
        path: true,
        custom: true,
      },
      // {
      //   label: "Invite",
      //   value: "My Account/Invite",
      // },
      // {
      //   label: "Add Account",
      //   value: "My Account/Add Account",
      // },
      // {
      //   label: "Switch Account",
      //   value: "My Account/Switch Account",
      // },
      // {
      //   label: "Upgrade Premium",
      //   value: "My Account/Upgrade Premium",
      // },
      // {
      //   label: "Settings",
      //   value: "My Account/Settings",
      // },
      // {
      //   label: "Help & Support",
      //   value: "My Account/Help & Support",
      // },
      // {
      //   label: "Billing & Payment",
      //   value: "My Account/Billing & Payment",
      // },
      // {
      //   label: "Ballance",
      //   value: "My Account/Ballance",
      // },
      // {
      //   label: "Scoring Points",
      //   value: "My Account/Scoring Points",
      // },
      // {
      //   label: "Send Feedback",
      //   value: "My Account/Send Feedback",
      // },
    ],
  },
];

export default function MultiLevelMenu() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const userNavigate = {
    HCP: "hcp-profile",
    Center: "hcc-profile",
    Hospital: "mc-profile",
  };

  const filteredData = data.map((node) => ({
    ...node,
    children: node.children
      ?.map((child) => {
        if (child.label === "Basic Profile" && user?.is_premium === 1) {
          return { ...child, label: "Premium Profile" };
        }
        return child;
      })
      .filter((child) => {
        return user?.is_premium !== 1 || child.type !== "premium";
      }),
  }));

  const handleNavigation = (node) => {
    if (node.path) {
      if (node.custom) {
        navigate(`/${node.value}`);
      } else {
        navigate(
          `/${userNavigate[user?.user_type]}/my-profile?id=${user?.id}`,
          {
            state: { userID: user?.id },
          }
        );
      }
    }
  };

  return (
    <>
      {user ? (
        <Tree
          data={filteredData}
          className="text-submain hover:text-main text-md font-semibold font-paragraphFont w-100 flex items-start justify-between "
          levelOffset={23}
          renderNode={({ node, expanded, hasChildren, elementProps }) => (
            <Group
              gap={5}
              {...elementProps}
              className="flex-row-reverse !justify-between text-submain hover:text-main !w-100"
            >
              {hasChildren && (
                <i
                  className="bi bi-chevron-compact-down text-base"
                  style={{
                    transform: expanded ? "rotate(180deg)" : "rotate(0deg)",
                  }}
                ></i>
                // <IconChevronDown
                //   size={18}
                //   style={{
                //     transform: expanded ? "rotate(180deg)" : "rotate(0deg)",
                //   }}
                // />
              )}
              <span
                className="!w-100"
                onClick={() => {
                  handleNavigation(node);
                }}
              >
                {node.label}
              </span>
            </Group>
          )}
        />
      ) : null}
    </>
  );
}
