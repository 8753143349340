import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "../Auth/ConfigApi";

export const JobsApi = createApi({
  reducerPath: "jobsApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["JobsApi", "deleteJob", "changeStatus", "jobApply"],

  endpoints: (builder) => ({
    fitsJobs: builder.query({
      query: (params) => ({
        url: `/v1/jobs/topRelated`,
        params,
      }),
      providesTags: ["JobsApi"],
    }),

    topRelatedJobs: builder.query({
      query: (params) => ({
        url: `/v1/jobs/search`,
        params,
      }),
      providesTags: ["JobsApi"],
    }),

    jobProfile: builder.query({
      query: ({ id }) => ({
        url: `/v1/jobs`,
        params: { id },
      }),
      providesTags: ["JobsApi", "changeStatus", "jobApply"],
    }),

    getCountries: builder.query({
      query: () => ({
        url: `/countries`,
      }),
      providesTags: ["JobsApi"],
    }),

    getCountryCities: builder.query({
      query: ({ id }) => ({
        url: `/countryCities?country_id=${id}`,
      }),
      providesTags: ["JobsApi"],
    }),

    employmentTypes: builder.query({
      query: () => ({
        url: `/v1/jobs/employmentTypes`,
      }),
      providesTags: ["JobsApi"],
    }),
    companyIndustries: builder.query({
      query: () => ({
        url: `/v1/jobs/companyIndustries`,
      }),
      providesTags: ["JobsApi"],
    }),
    seniorityLevels: builder.query({
      query: () => ({
        url: `/v1/jobs/seniorityLevels`,
      }),
      providesTags: ["JobsApi"],
    }),
    changeStatus: builder.mutation({
      query: (props) => ({
        url: `/v1/jobs/changeStatus`,
        method: "PUT",
        body: {
          id: props?.id,
          job_status: props?.job_status,
        },
      }),
      invalidatesTags: ["changeStatus"],
    }),
    jobApply: builder.mutation({
      query: (props) => ({
        url: `/v1/jobs/apply`,
        method: "POST",
        body: props,
      }),
      invalidatesTags: ["jobApply"],
    }),
    addJob: builder.mutation({
      query: (params) => ({
        url: "/v1/jobs/create",
        method: "POST",
        body: params,
      }),
      invalidatesTags: ["JobsApi"],
    }),
    deleteJob: builder.mutation({
      query: (id) => ({
        url: `/v1/jobs/delete?id=${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["deleteJob"],
    }),

    editJob: builder.mutation({
      query: (params) => ({
        url: `/v1/jobs/edit`,
        method: "PUT",
        body: params,
      }),
      invalidatesTags: ["JobsApi"],
    }),

    getTags: builder.query({
      query: () => "v1/jobs/tags",
    }),

    getCompanyTypes: builder.query({
      query: () => "v1/jobs/companyTypes",
    }),

    getCities: builder.query({
      query: (id) => `/countryCities?country_id=${id}`,
    }),
  }),
});

export const {
  useFitsJobsQuery,
  useTopRelatedJobsQuery,
  useJobProfileQuery,
  useGetCountriesQuery,
  useLazyGetCountryCitiesQuery,
  useEmploymentTypesQuery,
  useCompanyIndustriesQuery,
  useSeniorityLevelsQuery,
  useChangeStatusMutation,
  useJobApplyMutation,
  useAddJobMutation,
  useDeleteJobMutation,
  useEditJobMutation,
  useGetTagsQuery,
  useGetCompanyTypesQuery,
  useGetCitiesQuery,
} = JobsApi;
