import { SpecialitiesApi } from "./Dropdowns/SpecialityApi";
import { Articles } from "./Home/ArticlesApi";
import { EventApi } from "./Home/EventsApi";
import { GroupDiscussion } from "./Home/GroupsApi";
import { Newsletter } from "./Home/NewsletterApi";
import { HCPApi } from "./ListingPages/HCPApi";
import { authApi } from "./Login/AuthApi";
import { VerficationLoginsApi } from "./Login/VerficationLoginsApi";
import { Search } from "./Search/SearchApi";
import { IPv4API } from "./withoutBASE_URL/IPv4_API";
import { Educations } from "./Home/EducationsApi";
import { NotificationApi } from "./Home/NotificationApi";
import { CompaniesApi } from "./Dropdowns/Companies";
import { MedicalCenterApi } from "./ListingPages/MedicalCenter";
import { UserNewsLetter } from "./NewsLetter/UserNewsLetter";
import { sharedAPI } from "./shared/API";
import { ZoomMeetings } from "./withoutBASE_URL/ZoomMettings";
import { ScoringPoints } from "./ScoringPoints/ScoringPointsApi";
import { GroupModuleApi } from "./GroupsAndDiscussions/GroupModuleApi";
import { DiscussionsModuleApi } from "./GroupsAndDiscussions/DiscussionsModuleApi";
import { CountriesApi } from "./Dropdowns/CountriesApi";
import { MessagingCenter } from "./MessagingCenter/MessagingCenter";
import { Payment } from "./Payment/Payment";
import {Maintenance} from './Maintenance/Maintenance'
import { JobsApi } from "./Home/JobsApi";
// import { GroupModuleApi } from "./GroupsAndDiscussions/GroupModuleApi";
// import { DiscussionsModuleApi } from "./GroupsAndDiscussions/DiscussionsModuleApi";

const middlewares = [
  authApi.middleware,
  VerficationLoginsApi.middleware,
  IPv4API.middleware,
  ZoomMeetings.middleware,
  Articles.middleware,
  GroupDiscussion.middleware,
  EventApi.middleware,
  Newsletter.middleware,
  Search.middleware,
  HCPApi.middleware,
  MedicalCenterApi.middleware,
  SpecialitiesApi.middleware,
  CompaniesApi.middleware,
  Educations.middleware,
  NotificationApi.middleware,
  UserNewsLetter.middleware,
  sharedAPI.middleware,
  ScoringPoints.middleware,
  GroupModuleApi.middleware,
  DiscussionsModuleApi.middleware,
  CountriesApi.middleware,
  MessagingCenter.middleware,
  Payment.middleware,
  Maintenance.middleware,
  JobsApi.middleware,
  // GroupModuleApi.middleware,
  // DiscussionsModuleApi.middleware,
];

export default middlewares;
