import * as Yup from "yup";
export const validationSchema = Yup.object().shape({
  country: Yup.string().required("Countries Is Required"),
  city: Yup.string().required("Cities Is Required"),
  job_company_industry: Yup.string().required(
    "Job Company Industry Is Required"
  ),
  job_company_industry_other: Yup.mixed().when("job_company_industry", {
    is: "Other",
    then: () => Yup.string().required("Job Company Industry Is Required"),
  }),
  job_company_type: Yup.string().required("Job Company Type Is Required"),
  job_title: Yup.string()
    .matches(
      /^[a-zA-Z0-9\-(), ]*$/,
      "Job title can only contain letters, numbers, spaces, and basic special characters (-, ,)"
    )
    .min(3, "Minimum chars is 3")
    .max(100, "Maximum chars is 100")
    .required("Job Title Is Required"),
  job_role: Yup.string()
    .matches(
      /^[a-zA-Z0-9\-(), ]*$/,
      "Job role can only contain letters, numbers, spaces, and basic special characters (-, ,)"
    )
    .min(3, "Minimum chars is 3")
    .max(100, "Maximum chars is 100")
    .required("Job Role Is Required"),
  job_seniority_level: Yup.string().required("Job Seniority Level Is Required"),
  job_employmentType: Yup.string().required("Job Employment Is Required"),
  job_employmentTypeHours: Yup.mixed().when("job_employmentType", {
    is: "Working-hours",
    then: () =>
      Yup.number()
        .moreThan(0, "Please enter a valid number of hours")
        .required("Hours Is Required"),
  }),
  work_place_type: Yup.string().required("Work Place Type Is Required"),
  salary: Yup.string().required("Salary Is Required"),
  staticSalary: Yup.mixed().when("salary", {
    is: "static",
    then: () =>
      Yup.number()
        .moreThan(0, "Please enter a valid number of salary")
        .min(1000, "Minimum salary is 1000")
        .max(100000, "Maximum salary is 100000")
        .required("Salary Is Required"),
  }),
  vacancies: Yup.number()
    .min(1, " minimum value is 1")
    .max(45, " maximum value is 45")
    .required("Vacancies Is Required"),
  years_experience_from: Yup.number()
    .required("Years Experience From is required")
    .typeError("Must be a number")
    .min(0, "Minimum value is 0"),
  years_experience_to: Yup.string().notRequired(),
  description: Yup.string()
    .matches(
      /^[a-zA-Z0-9, ]*$/,
      "Job description can only contain letters, numbers, spaces, and commas (,)"
    )
    .min(50, "At least 50 characters")
    .max(2000, "Less than 2000 required")
    .required("Description Is Required"),
  key_responsibilities: Yup.string()
    .matches(
      /^[a-zA-Z0-9, ]*$/,
      "Key responsibilities can only contain letters, numbers, spaces, and commas (,)"
    )
    .min(50, "At least 50 characters")
    .max(2000, "Less than 2000 required")
    .required("Key Responsibilities Is Required"),
  qualifications: Yup.string()
    .matches(
      /^[a-zA-Z0-9, ]*$/,
      "Qualifications can only contain letters, numbers, spaces, and commas (,)"
    )
    .min(50, "At least 50 characters")
    .max(2000, "Less than 2000 required")
    .required("Qualifications Is Required"),
});
