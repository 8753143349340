import {
  Button,
  Grid,
  Group,
  Radio,
  RangeSlider,
  Select,
  TagsInput,
  Textarea,
  TextInput,
} from "@mantine/core";
import { FormGroup } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { validationSchema } from "./validationSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useMemo } from "react";
import {
  useAddJobMutation,
  useEditJobMutation,
  useGetCitiesQuery,
  useCompanyIndustriesQuery,
  useGetCompanyTypesQuery,
  useGetCountriesQuery,
  useSeniorityLevelsQuery,
  useGetTagsQuery,
  useEmploymentTypesQuery,
} from "../../../store/api/Home/JobsApi";
import { useNavigate } from "react-router-dom";
import { generateSlug } from "../../../units/GenerateSlug";

const FormJob = ({ editData, closeForm }) => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    control,
    watch,
    reset,
    setValue,
  } = useForm({ resolver: yupResolver(validationSchema), mode: "onChange" });

  const { data: companyIndustries, isSuccess: isSuccessCompanyIndustries } =
  useCompanyIndustriesQuery();

  const dataComCompanyIndustries = useMemo(() => {
    return isSuccessCompanyIndustries
      ? companyIndustries.map((company) => ({
          label: company.name,
          value: company.name,
        }))
      : [];
  }, [companyIndustries, isSuccessCompanyIndustries]);

  const { data: getTags, isSuccess: isSuccessGetTags } = useGetTagsQuery();

  const dataGetTags = useMemo(() => {
    return isSuccessGetTags ? getTags.map((tag) => tag.name) : [];
  }, [getTags, isSuccessGetTags]);

  const { data: companyTypes, isSuccess: isSuccessGetCompanyTypes } =
    useGetCompanyTypesQuery();

  const dataCompanyTypes = useMemo(() => {
    return isSuccessGetCompanyTypes
      ? companyTypes.map((companyType) => ({
          label: companyType.name,
          value: companyType.name,
        }))
      : [];
  }, [companyTypes, isSuccessGetCompanyTypes]);

  const { data: getSeniorityLevels, isSuccess: isSuccessGetSeniorityLevels } =
  useSeniorityLevelsQuery();

  const dataGetSeniorityLevels = useMemo(() => {
    return isSuccessGetSeniorityLevels
      ? getSeniorityLevels.map((seniorityLevel) => ({
          label: seniorityLevel.name,
          value: seniorityLevel.name,
        }))
      : [];
  }, [getSeniorityLevels, isSuccessGetSeniorityLevels]);

  const { data: getEmploymentTypes, isSuccess: isSuccessGetEmploymentTypes } =
    useEmploymentTypesQuery();

  const dataGetEmploymentTypes = useMemo(() => {
    return isSuccessGetEmploymentTypes
      ? getEmploymentTypes.map((employmentType) => ({
          label: employmentType.type,
          value: employmentType.type,
        }))
      : [];
  }, [getEmploymentTypes, isSuccessGetEmploymentTypes]);

  const [
    addJob,
    { isLoading, data: dataForResponse, isSuccess: isSuccessDataForResponse },
  ] = useAddJobMutation();
  const [editJob, { isLoading: isLoadingEditJob }] = useEditJobMutation();

  const countryChanging = watch("country");

  const countryId = useMemo(() => {
    return countryChanging ? JSON.parse(countryChanging)?.id : undefined;
  }, [countryChanging]);

  const { data: countries, isSuccess: isSuccessGetCountries } =
    useGetCountriesQuery();
  const { data: cities, isSuccess: isSuccessGetCities } = useGetCitiesQuery(
    countryId,
    { skip: !countryId }
  );

  const handleSubmitData = async (data) => {
    const countryIdForDataBase = JSON.parse(data.country);
    const payload = {
      ...data,
      salary: {
        type: data.salary,
        value: data?.staticSalary ? data.staticSalary : "",
        from: data?.range !== undefined ? data.range[0] : 0.0,
        to: data?.range !== undefined ? data.range[1] : 0.0,
      },
      city_id: data?.city,
      country_id: countryIdForDataBase?.id,
      job_company_industry:
        data?.job_company_industry === "Other"
          ? data?.job_company_industry_other
          : data?.job_company_industry,
      job_employment: {
        type: data?.job_employmentType,
        working_hours: data?.job_employmentTypeHours
          ? data.job_employmentTypeHours
          : 0,
      },
    };

    try {
      if (editData) {
        await editJob({ id: editData?.id, ...payload });
        closeForm();
      } else {
        await addJob(payload);
      }
      reset();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isSuccessDataForResponse) {
      return navigate(
        `/job/${generateSlug(dataForResponse.data.job_title)}?id=${
          dataForResponse?.data?.id
        }`
      );
    }
  }, [
    dataForResponse?.data?.id,
    dataForResponse?.data?.job_title,
    isSuccessDataForResponse,
    navigate,
  ]);

  const handelDataForCountries = useMemo(() => {
    return (
      isSuccessGetCountries &&
      countries.map((country) => ({
        label: country.name,
        value: JSON.stringify({ id: country.id, code: country.code }),
      }))
    );
  }, [countries, isSuccessGetCountries]);

  const handelDataForCities = useMemo(() => {
    return isSuccessGetCities && countryChanging
      ? cities?.data[0]?.cities.map((city) => ({
          label: city.name,
          value: JSON.stringify(city.id),
        }))
      : [];
  }, [cities?.data, countryChanging, isSuccessGetCities]);

  // handel default value for selectList from response to edit job
  useEffect(() => {
    setValue("tags", editData ? editData?.tags.map((tag) => tag.name) : []);
    if (editData) {
      setValue(
        "country",
        JSON.stringify({ id: editData.location.country.id }) || ""
      );
      setValue("city", editData.location.city.id || "");
      setValue("job_company_industry", editData?.job_company_industry || "");
      setValue("job_company_type", editData?.job_company_type || "");
      setValue("job_seniority_level", editData?.job_seniority_level || "");
      setValue("job_employmentType", editData?.job_employment.type || "");
      setValue("work_place_type", editData?.work_place_type || "");
    }
    setValue("salary", editData ? editData?.salary.type : "static");
  }, [editData, setValue]);

  return (
    <section>
      <div className="py-10">
        <form
          className="w-full flex flex-col gap-4"
          onSubmit={handleSubmit(handleSubmitData)}
        >
          <h5 className="p-2">Job Details</h5>
          <Grid>
            <Grid.Col>
              <FormGroup>
                <Controller
                  name="tags"
                  control={control}
                  render={({ field }) => (
                    <TagsInput
                      label="Press Enter to submit a tag"
                      placeholder="Enter Tag"
                      maxTags={5}
                      clearable
                      data={dataGetTags}
                      {...field}
                      error={errors?.tags?.message}
                    />
                  )}
                />
              </FormGroup>
            </Grid.Col>
            <Grid.Col
              className="flex flex-col gap-4"
              span={{ base: 12, xl: 6, lg: 6, md: 6, sm: 12 }}
            >
              <FormGroup>
                <Controller
                  name="country"
                  control={control}
                  render={({ field }) => (
                    <Select
                      withAsterisk
                      {...field}
                      label="Select Your Country"
                      placeholder="Select Your Country"
                      data={handelDataForCountries}
                      defaultSearchValue={
                        editData && editData?.location.country.name
                      }
                      searchable
                      error={errors?.country?.message}
                    />
                  )}
                />
              </FormGroup>
              <FormGroup>
                <Controller
                  name="city"
                  control={control}
                  render={({ field }) => (
                    <Select
                      withAsterisk
                      {...field}
                      label="Select Your City"
                      placeholder="Select Your City"
                      data={handelDataForCities}
                      defaultSearchValue={
                        editData && editData?.location.city.name
                      }
                      searchable
                      error={errors?.city?.message}
                    />
                  )}
                />
              </FormGroup>
              <FormGroup>
                <Controller
                  name="job_company_industry"
                  control={control}
                  render={({ field }) => (
                    <Select
                      withAsterisk
                      {...field}
                      label="Company Industry"
                      placeholder="Select Company Industry"
                      data={[
                        ...dataComCompanyIndustries,
                        { label: "Other", value: "Other" },
                      ]}
                      error={errors?.job_company_industry?.message}
                    />
                  )}
                />
                {watch("job_company_industry") === "Other" && (
                  <TextInput
                    withAsterisk
                    label="Please specify your company industry"
                    {...register("job_company_industry_other")}
                    placeholder="Enter your industry"
                    defaultValue={editData && editData?.job_company_industry}
                    error={errors?.job_company_industry_other?.message}
                  />
                )}
              </FormGroup>
              <FormGroup>
                <Controller
                  name="job_company_type"
                  control={control}
                  render={({ field }) => (
                    <Select
                      withAsterisk
                      {...field}
                      label="Company Type"
                      placeholder="Select Company Type"
                      data={dataCompanyTypes}
                      error={errors?.job_company_type?.message}
                    />
                  )}
                />
              </FormGroup>
              <FormGroup>
                <TextInput
                  {...register("job_title")}
                  placeholder="Job Title"
                  withAsterisk
                  label="Job Title"
                  defaultValue={editData && editData?.job_title}
                  error={errors?.job_title?.message}
                />
              </FormGroup>
              <FormGroup>
                <TextInput
                  {...register("job_role")}
                  placeholder="Job Role"
                  label="Job Role"
                  withAsterisk
                  defaultValue={editData && editData?.job_role}
                  error={errors?.job_role?.message}
                />
              </FormGroup>
            </Grid.Col>

            <Grid.Col
              className="flex flex-col gap-4"
              span={{ base: 12, xl: 6, lg: 6, md: 6, sm: 12 }}
            >
              <FormGroup>
                <Controller
                  name="job_seniority_level"
                  control={control}
                  render={({ field }) => (
                    <Select
                      withAsterisk
                      {...field}
                      label="Seniority level"
                      placeholder="Select Seniority level"
                      data={dataGetSeniorityLevels}
                      error={errors?.job_seniority_level?.message}
                    />
                  )}
                />
              </FormGroup>
              <FormGroup>
                <Controller
                  name="job_employmentType"
                  control={control}
                  render={({ field }) => (
                    <Select
                      withAsterisk
                      {...field}
                      label="Employment Type"
                      placeholder="Select Employment Type"
                      data={dataGetEmploymentTypes}
                      error={errors?.job_employmentType?.message}
                    />
                  )}
                />
                {watch("job_employmentType") === "Working-hours" && (
                  <TextInput
                    label="Please specify number of hours"
                    placeholder="Enter number of hours"
                    type="number"
                    withAsterisk
                    defaultValue={
                      editData && editData?.job_employment.working_hours
                    }
                    {...register("job_employmentTypeHours")}
                    error={errors?.job_employmentTypeHours?.message}
                  />
                )}
              </FormGroup>
              <FormGroup>
                <Controller
                  name="work_place_type"
                  control={control}
                  render={({ field }) => (
                    <Select
                      withAsterisk
                      {...field}
                      label="Workplace Type"
                      placeholder="Select Workplace Type"
                      data={["On-Site", "Hybrid", "Remote"]}
                      error={errors?.work_place_type?.message}
                    />
                  )}
                />
              </FormGroup>
              <FormGroup>
                <Controller
                  name="salary"
                  control={control}
                  render={({ field }) => (
                    <Radio.Group
                      {...field}
                      label="Salary"
                      withAsterisk
                      required
                      defaultValue={"static"}
                      error={errors?.salary?.message}
                    >
                      <Group mt="xs">
                        <Radio value="static" label="Static salary" />
                        <Radio value="range" label="Salary range" />
                        <Radio
                          value="To be Discussed in the interview"
                          label="To be Discussed in the interview"
                        />
                      </Group>
                    </Radio.Group>
                  )}
                />
                {watch("salary") === "range" && (
                  <Controller
                    name="range"
                    control={control}
                    defaultValue={
                      editData?.salary?.type === "range"
                        ? [
                            Number(editData?.salary?.from),
                            Number(editData?.salary?.to),
                          ]
                        : [1000.01, 100000.01]
                    }
                    render={({ field }) => (
                      <RangeSlider
                        className="my-2"
                        min={1000.01}
                        max={10000.01}
                        step={0.1}
                        {...field}
                      />
                    )}
                  />
                )}
                {watch("salary") === "static" && (
                  <TextInput
                    withAsterisk
                    label="Please Enter Static Salary"
                    placeholder="Enter Static Salary"
                    type="number"
                    defaultValue={editData && editData?.salary?.value}
                    {...register("staticSalary")}
                    error={errors?.staticSalary?.message}
                  />
                )}
              </FormGroup>
              <FormGroup>
                <TextInput
                  placeholder="Number Of Vacancies"
                  label="Number Of Vacancies"
                  withAsterisk
                  {...register("vacancies")}
                  type="number"
                  defaultValue={editData && editData?.vacancies}
                  error={errors?.vacancies?.message}
                />
              </FormGroup>
              <FormGroup>
                <TextInput
                  placeholder="From"
                  withAsterisk
                  label="Preferred candidate years of experiences from"
                  {...register("years_experience_from")}
                  type="number"
                  defaultValue={editData && editData?.years_experience?.from}
                  error={errors?.years_experience_from?.message}
                />
              </FormGroup>
              <FormGroup>
                <TextInput
                  placeholder="To"
                  label="Preferred candidate years of experiences to"
                  {...register("years_experience_to")}
                  type="number"
                  defaultValue={editData && editData?.years_experience?.to}
                  error={errors?.years_experience_to?.message}
                />
              </FormGroup>
            </Grid.Col>
          </Grid>
          <Grid>
            <Grid.Col>
              <FormGroup>
                <h5 className="py-3">Job Description</h5>
                <Textarea
                  withAsterisk
                  defaultValue={editData && editData?.description}
                  {...register("description")}
                  error={errors?.description?.message}
                  placeholder="Job Description"
                />
              </FormGroup>
              <FormGroup>
                <h5 className="py-3">Key Responsibilities</h5>
                <Textarea
                  withAsterisk
                  defaultValue={editData && editData?.key_responsibilities}
                  {...register("key_responsibilities")}
                  error={errors?.key_responsibilities?.message}
                  placeholder="Key Responsibilities"
                />
              </FormGroup>
              <FormGroup>
                <h5 className="py-3">Qualifications</h5>
                <Textarea
                  withAsterisk
                  defaultValue={editData && editData?.qualifications}
                  {...register("qualifications")}
                  error={errors?.qualifications?.message}
                  placeholder="Qualifications"
                />
              </FormGroup>
              <div className="flex justify-end">
                <Button
                  loading={isLoading || isLoadingEditJob}
                  loaderProps={{ type: "dots" }}
                  disabled={!isValid || isLoading || isLoadingEditJob}
                  type="submit"
                  color="#37e0b1"
                  className="my-3"
                >
                  {editData ? "Update Job" : "Create Job"}
                </Button>
              </div>
            </Grid.Col>
          </Grid>
        </form>
      </div>
    </section>
  );
};

export default FormJob;
