import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "../Auth/ConfigApi";
export const CountriesApi = createApi({
  reducerPath: "Countries",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Country"],

  endpoints: (builder) => ({
    getCountries: builder.query({
      query: () => ({
        url: `/countries`,
      }),
      providesTags: ["Country"],
    }),
  }),
});

export const { useGetCountriesQuery } = CountriesApi;
