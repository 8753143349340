import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "../Auth/ConfigApi";

export const Payment = createApi({
    reducerPath: "Payment",
    baseQuery: baseQueryWithReauth,
    tagTypes: ["payment", "transactionHistory"],

    endpoints: (builder) => ({
        payment: builder.mutation({
            query: (props) => {
                return {  
                    method: 'POST',
                    url: `/payment/checkout`, 
                    params: {...props}
                };
            },
            invalidatesTags: ["payment"], 
        }),
        transaction: builder.query({
            query: () => {
                return {  
                    method: 'GET',
                    url: `/payment/transaction`, 
                };
            },
            invalidatesTags: ["transactionHistory"], 
        }),
    }),
});

export const {
    usePaymentMutation,
    useTransactionQuery,
} = Payment;
