import { Indicator, Menu, Loader, Avatar } from "@mantine/core";
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { GoCheckCircle, GoXCircle } from "react-icons/go";
import { useHandleConnectionsMutation } from "../../store/api/ListingPages/HCPApi";
import {
  useGetNotificationsListQuery,
  useLazyNotificationIsReadQuery,
  useNotificationIsReadQuery,
} from "../../store/api/Home/NotificationApi"; // You can keep the original API
import { generateSlug } from "../../units/GenerateSlug";
import { IoCheckmarkDone, IoSettingsOutline } from "react-icons/io5";
import { MdOutlineCancel } from "react-icons/md";
import { BiUserPlus } from "react-icons/bi";

const notificationTypes = {
  connection_request: {
    slug: "Connection Request",
    icon: <i className="bi bi-person-plus text-base text-white"></i>,
  },
  message_received: {
    slug: "Message Received",
    icon: <i className="bi bi-envelope-fill text-base text-white"></i>,
  },
  article_review: {
    slug: "Article Review",
    icon: <i className="bi bi-journal-text text-base text-white"></i>,
  },
  education_review: {
    slug: "Education Review",
    icon: <i className="bi bi-book text-base text-white"></i>,
  },
  follow: {
    slug: "Follow",
    icon: <i className="bi bi-heart-fill text-base text-white"></i>,
  },
  "article-like": {
    slug: "Like",
    // icon: <i className="bi bi-person-plus text-base text-white"></i>,
  },
  "education-like": {
    slug: "Like",
    // icon: <i className="bi bi-person-plus text-base text-white"></i>,
  },
  "profile-like": {
    slug: "Like",
    // icon: <i className="bi bi-person-plus text-base text-white"></i>,
  },
  "service-request-accepted": {
    slug: "Service Request Accepted",
    icon: <IoCheckmarkDone />,
  },

  "service-request-rejected": {
    slug: "Service Request Rejected",
    icon: <MdOutlineCancel />,
  },

  "service-request-maintained": {
    slug: "Service Request Maintained",
    icon: <IoSettingsOutline />,
  },

  "service-request-completed": {
    slug: "Service Request Completed",
    icon: <IoCheckmarkDone />,
  },

  "new-service-request": {
    slug: "New Service Request",
    icon: <BiUserPlus />,
  },
  "group-request-accept": {
    slug: "Group Request-Accept",
    // icon: <i className="bi bi-person-plus text-base text-white"></i>,
  },

  "group-request-reject": {
    slug: "Group Request Reject",
    // icon: <i className="bi bi-person-plus text-base text-white"></i>,
  },

  "group-kick": {
    slug: "Group Kick",
    // icon: <i className="bi bi-person-plus text-base text-white"></i>,
  },

  "added-group-admin": {
    slug: "Added Group Admin",
    // icon: <i className="bi bi-person-plus text-base text-white"></i>,
  },

  "removed-group-admin": {
    slug: "Removed Group Admin",
    // icon: <i className="bi bi-person-plus text-base text-white"></i>,
  },
};

export default function NotificationMenu() {
  const [openNotification, setOpenNotification] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Fetch notifications initially using the query
  const {
    data: initialNotifications,
    refetch,
    isFetching,
    isSuccess,
  } = useGetNotificationsListQuery();

  const [triggerNotificationIsRead] = useLazyNotificationIsReadQuery();

  const [handleConnections] = useHandleConnectionsMutation();

  // Function to refetch notifications
  const fetchNotifications = async () => {
    setIsLoading(true);
    try {
      await refetch(); // Manually refetch data
    } catch (error) {
      console.error("Error fetching notifications:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch notifications once on component load
  useEffect(() => {
    !isFetching && fetchNotifications(); // Fetch on component load
  }, []); // Only run once

  useEffect(() => {
    if (initialNotifications) {
      setNotifications(initialNotifications?.data || []);
      setIsLoading(false);
    }
  }, [isSuccess]);

  // Handle accepting or rejecting connection requests
  const handleConnectionResponse = async (response, notificationId, userId) => {
    try {
      // Optimistically update the notifications UI
      setNotifications((prevNotifications) =>
        prevNotifications?.filter((n) => n?.id !== notificationId)
      );

      // Handle the connection request (accept/reject)
      await handleConnections({
        id: userId,
        status: response,
      }).unwrap();

      console.log(`Connection ${response} for user ${userId} was successful.`);
    } catch (error) {
      console.error(
        `Error handling connection ${response} for user ${userId}:`,
        error
      );
      // Optionally, you can re-add the notification if the API call fails
      fetchNotifications();
    }
  };

  // Sort and filter notifications
  const sortedNotifications = notifications
    ?.filter(
      (notification) =>
        ![
          "connection_accepted",
          "connection_rejected",
          "mention_in_post",
        ].includes(notification?.notification_type)
    ).filter(item => item?.is_read !== 1) 
    .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
    .slice(0, 3);

  const handleNotificationClick = (userName, userId, userType = "HCP") => {
    console.log("userType", userType);
    console.log("name", userName);
    console.log(userId);
    const userNavigate = {
      connection_request: "hcp-profile",
      message_received: "hcp-profile",
      article_review: "hcp-profile",
      education_review: "hcp-profile",
      follow: "hcp-profile",
      "article-like": "hcp-profile",
      "education-like": "hcp-profile",
      "profile-like": "hcp-profile",

      "service-request-accepted": "Hospital",
      "service-request-rejected": "Hospital",
      "service-request-maintained": "Hospital",
      "service-request-completed": "Hospital",
      "new-service-request": "Center",

      "group-request-accept": "hcp-profile",
      "group-request-reject": "hcp-profile",
      "group-kick": "hcp-profile",
      "added-group-admin": "hcp-profile",
      "removed-group-admin": "hcp-profile",
    };
    const profilePath = userNavigate[userType];

    navigate(`/${profilePath}/${generateSlug(userName)}?id=${userId}`, {
      state: { userID: userId },
    });
  };

  const handleOpenNotification = async (value) => {
    setOpenNotification(value);
    if(value) {
      await triggerNotificationIsRead()
    }else {
      const response = await refetch()
      if (response?.data?.data) {
        setNotifications(response?.data?.data || []);
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      {/* Notification Menu */}
      <Menu
        shadow="md"
        width={300}
        position="bottom-end"
        offset={1}
        withArrow
        arrowPosition="center"
        opened={openNotification}
        onChange={handleOpenNotification}
      >
        <Menu.Target>
          <Indicator
            inline
            color="#012e54"
            size={14}
            label={sortedNotifications?.length}
            position="top-end"
            className="cursor-pointer hover:bg-bgmain hover:rounded-full hover:w-auto p-1"
          >
            <i className="bi bi-bell-fill text-main text-xl"></i>
          </Indicator>
        </Menu.Target>
        <Menu.Dropdown className="h-auto" style={{ top: "70.5px" }}>
          <Menu.Label>Notifications</Menu.Label>

          {/* Loading State */}
          {isLoading && <Loader size="sm" />}

          {/* No Notifications */}
          {sortedNotifications?.length === 0 &&  !isLoading && (
            <Menu.Item
              className="w-100 !bg-body p-2 rounded-1"
              leftSection={
                <i className="bi bi-x-circle-fill text-deepred text-base"></i>
              }
            >
              <Link
                to="/notifications"
                className="no-underline hover:underline"
                style={{ color: "#868e96" }}
              >
                <h6 className="text-submain text-xs font-title m-0">
                  There are no notifications yet!
                </h6>
              </Link>
            </Menu.Item>
          )}

          {/* Render Notifications */}
          {sortedNotifications?.map((notification) => {
            const notificationType =
              notificationTypes[notification?.notification_type];
            return (
              <Menu.Item
                key={notification?.id}
                className="w-100 !bg-body p-2 rounded-1"
                onClick={() =>
                  handleNotificationClick(
                    notification?.notifiable_name,
                    notification?.notifiable_id,
                    notification?.notification_type
                  )
                }
                style={{ cursor: "pointer" }}
              >
                <div className="flex items-center gap-2">
                  <Avatar className="bg-main" radius="xl" size={24}>
                    {notificationType?.icon}
                  </Avatar>

                  <div className="flex-grow">
                    <h6 className="text-main text-xs font-title m-0">
                      {notificationType?.slug}
                    </h6>
                    <p className="truncated-text text-text text-xs font-paragarphFont w-75 m-0">
                      {notification?.notification_data}
                    </p>
                  </div>

                  {notification?.notification_type === "connection_request" && (
                    <div className="flex gap-2">
                      <GoXCircle
                        size={26}
                        color="#e63946"
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent the menu item click event
                          handleConnectionResponse(
                            "reject",
                            notification?.id,
                            notification?.notifiable_id
                          );
                        }}
                      />
                      <GoCheckCircle
                        color="#37e0b1"
                        size={26}
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent the menu item click event
                          handleConnectionResponse(
                            "accept",
                            notification?.id,
                            notification?.notifiable_id
                          );
                        }}
                      />
                    </div>
                  )}
                </div>
              </Menu.Item>
            );
          })}

          <Menu.Label
            color="gray"
            className="w-100 me-auto ms-auto bg-light-subtle h-auto p-1 mt-2 text-sm cursor-pointer text-center"
          >
            <Link
              to="/notifications"
              className="no-underline hover:underline"
              style={{ color: "#868e96" }}
            >
              Show all notifications <i className="bi bi-arrow-right"></i>
            </Link>
          </Menu.Label>
        </Menu.Dropdown>
      </Menu>
    </>
  );
}
