import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "../Auth/ConfigApi";

export const Maintenance = createApi({
  reducerPath: "Maintenance",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Maintenance"],

  endpoints: (builder) => ({
    createNewDevice: builder.mutation({
      query: (props) => {
        return {
          method: "POST",
          url: `/maintenance/devices/create`,
          body: { ...props },
        };
      },
      invalidatesTags: ["getDevices"],
    }),
    deleteDevice: builder.mutation({
      query: ({ id }) => {
        return {
          method: "POST",
          url: `/maintenance/devices/delete/${id}`,
        };
      },
      invalidatesTags: ["getDevices"],
    }),
    updateDevice: builder.mutation({
      query: (props) => {
        console.log("====================================");
        console.log(props, "propsprops");
        console.log("====================================");
        return {
          method: "POST",
          url: `/maintenance/devices/update/${props?.id}`,
          body: { ...props },
        };
      },
      invalidatesTags: ["getDevices"],
    }),
    getSingleDevice: builder.query({
      query: ({ id }) => {
        return {
          method: "GET",
          url: `/maintenance/devices/${id}`,
        };
      },
      invalidatesTags: ["getSingleDevice"],
    }),
    getDevices: builder.query({
      query: (params) => ({
        method: "GET",
        url: `/maintenance/devices`,
        params,
      }),
      providesTags: ["getDevices"],
    }),
    getCategories: builder.query({
      query: (params) => ({
        method: "GET",
        url: `/maintenance/devices/categories`,
        params,
      }),
      providesTags: ["getCategories"],
    }),
    getSupplieres: builder.query({
      query: (params) => ({
        method: "GET",
        url: `/companies`,
        params,
      }),
      providesTags: ["getSupplieres"],
    }),
    getUserSupplieres: builder.query({
      query: (params) => ({
        method: "GET",
        url: `/maintenance/my-suppliers`,
        params,
      }),
      providesTags: ["getUserSupplieres"],
    }),
    addNewSupplier: builder.mutation({
      query: (props) => {
        return {
          method: "POST",
          url: `/maintenance/my-suppliers/create`,
          body: { ...props },
        };
      },
      invalidatesTags: ["getUserSupplieres"],
    }),
    updateNewSupplier: builder.mutation({
      query: (props) => {
        return {
          method: "POST",
          url: `/maintenance/my-suppliers/update/${props?.id}`,
          body: { ...props },
        };
      },
      invalidatesTags: ["getUserSupplieres"],
    }),
    getServices: builder.query({
      query: (params) => ({
        method: "GET",
        url: `maintenance/my-servicers`,
        params,
      }),
      providesTags: ["getServices"],
    }),
    updateService: builder.mutation({
      query: (props) => {
        return {
          method: "POST",
          url: `/maintenance/my-servicers/update/${props?.id}`,
          body: { ...props },
        };
      },
      invalidatesTags: ["getServices"],
    }),
    addNewServicer: builder.mutation({
      query: (props) => {
        return {
          method: "POST",
          url: `/maintenance/my-servicers/create`,
          body: props,
          // body: { ...props },
        };
      },
      invalidatesTags: ["getServices"],
    }),
    deleteSupplier: builder.mutation({
      query: (props) => {
        return {
          method: "POST",
          url: `/maintenance/my-suppliers/delete/${props?.id}`,
        };
      },
      invalidatesTags: ["getDevices"],
    }),
    deleteServicer: builder.mutation({
      query: (props) => {
        return {
          method: "POST",
          url: `/maintenance/my-suppliers/delete/${props?.id}`,
        };
      },
      invalidatesTags: ["getDevices"],
    }),
    getCompanyBuyers: builder.query({
      query: (params) => ({
        method: "GET",
        url: `/centers`,
        params,
      }),
      providesTags: ["getCompanyBuyers"],
    }),
    getBuyers: builder.query({
      query: (params) => ({
        method: "GET",
        url: `/maintenance/my-buyers`,
        params,
      }),
      providesTags: ["getBuyers"],
    }),
    addNewBuyer: builder.mutation({
      query: (props) => {
        return {
          method: "POST",
          url: `/maintenance/my-buyers/create`,
          body: { ...props },
        };
      },
      invalidatesTags: ["getBuyers"],
    }),

    updateBuyer: builder.mutation({
      query: (props) => {
        return {
          method: "POST",
          url: `/maintenance/my-buyers/update/${props?.id}`,
          body: { ...props },
        };
      },
      invalidatesTags: ["getBuyers"],
    }),

    createServiceRequest: builder.mutation({
      query: (props) => {
        return {
          method: "POST",
          url: `maintenance/service-requests/create`,
          body: props,
        };
      },
      invalidatesTags: ["serviceRequests , maintenance "],
    }),

    deleteServiceRequest: builder.mutation({
      query: ({ id }) => {
        return {
          method: "POST",
          url: `/maintenance/service-requests/delete/${id}`,
        };
      },
      invalidatesTags: ["serviceRequests"],
    }),

    getServiceRequests: builder.query({
      query: (params) => ({
        method: "GET",
        url: `/maintenance/service-requests`,
        params,
      }),
      providesTags: ["getServiceRequests"],
    }),

    updateServiceRequestStatus: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `maintenance/service-requests/handle/${params?.id}`,
          params,
        };
      },
      invalidatesTags: ["serviceRequests , maintenance "],
    }),
  }),
});

export const {
  useCreateNewDeviceMutation,
  useDeleteDeviceMutation,
  useGetDevicesQuery,
  useGetCategoriesQuery,
  useLazyGetSingleDeviceQuery,
  useUpdateDeviceMutation,
  useGetSupplieresQuery,
  useAddNewSupplierMutation,
  useUpdateNewSupplierMutation,
  useGetUserSupplieresQuery,
  useGetServicesQuery,
  useUpdateServiceMutation,
  useAddNewServicerMutation,
  useDeleteSupplierMutation,
  useDeleteServicerMutation,
  useGetBuyersQuery,
  useAddNewBuyerMutation,
  useUpdateBuyerMutation,
  useGetCompanyBuyersQuery,

  useCreateServiceRequestMutation,
  useDeleteServiceRequestMutation,
  useGetServiceRequestsQuery,
  useUpdateServiceRequestStatusMutation,
  useLazyGetServiceRequestsQuery,
} = Maintenance;
