export const validateImage = (file, setImageError) => {
  const MAX_FILE_SIZE = 5 * 1024 * 1024;
  const ALLOWED_TYPES = ["image/jpeg", "image/png", "image/gif"];
  if (typeof file === "string" && file.startsWith("https")) {
    return true;
  }

  if (file instanceof File) {
    if (!ALLOWED_TYPES.includes(file.type)) {
      setImageError(
        "Invalid file type. Please upload a JPEG, PNG, or GIF image."
      );
      return false;
    }

    if (file.size > MAX_FILE_SIZE) {
      setImageError(`File size exceeds 5MB. Please upload a smaller image.`);
      return false;
    }

    setImageError("");
    return true;
  }
};
