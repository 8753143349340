import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "../Auth/ConfigApi";

export const EventApi = createApi({
  reducerPath: "EventApi",
  baseQuery: baseQueryWithReauth,

  tagTypes: ["EventApi", "Events", "form"],

  endpoints: (builder) => ({
    eventCard: builder.query({
      query: (params) => ({
        method: "Get",
        url: `/home/events`,
        params,
      }),
      providesTags: ["EventApi"],
    }),

    getAllEvents: builder.query({
      query: (params) => ({
        method: "Get",
        url: `/events`,
        params,
      }),
      providesTags: ["getAllEvents"],
      keepUnusedDataFor: 0,
    }),

    getUserCalendarEvents: builder.query({
      query: (params) => ({
        method: "Get",
        url: `/calendar/events`,
        params,
      }),
      providesTags: ["EventApi"],
    }),

    getGroupEvents: builder.query({
      query: (params) => ({
        method: "Get",
        url: `/events-group`,
        params,
      }),
      providesTags: ["EventApi"],
    }),

    getSingleEvent: builder.query({
      query: (id) => ({
        method: "GET",
        url: `/events/${id}`,
      }),
      providesTags: ["EventApi"],
    }),

    getTimeZones: builder.query({
      query: (id) => ({
        method: "GET",
        url: `/time_zones`,
      }),
      providesTags: ["EventApi"],
    }),

    createEvent: builder.mutation({
      query: (props) => ({
        method: "POST",
        url: `/events`,
        body: props,
      }),
      extraOptions: { multipart: true },
      invalidatesTags: ["Events", "form"],
    }),

    updateEvent: builder.mutation({
      query: ({ id, props }) => ({
        method: "POST",
        url: `/events/update/${id}`,
        body: props,
      }),
      providesTags: ["EventApi"],
    }),

    deleteEvent: builder.mutation({
      query: (id) => ({
        method: "DELETE",
        url: `/events/${id}`,
      }),
      providesTags: ["EventApi"],
    }),

    createEventOrganizer: builder.mutation({
      query: ({ id, props }) => ({
        method: "POST",
        url: `/events/${id}/organizers`,
        body: props,
      }),
      extraOptions: { multipart: true },
      invalidatesTags: ["Events", "form"],
    }),

    updateEventOrganizer: builder.mutation({
      query: ({ id, organizerID, props }) => ({
        method: "POST",
        url: `/events/${id}/organizers/update/${organizerID}`,
        body: props,
      }),
      providesTags: ["EventApi"],
    }),

    deleteEventOrganizer: builder.mutation({
      query: ({ eventID, organizerID }) => ({
        method: "DELETE",
        url: `/events/${eventID}/organizers/${organizerID}`,
      }),
      providesTags: ["EventApi"],
    }),

    createEventSpeaker: builder.mutation({
      query: ({ id, props }) => ({
        method: "POST",
        url: `/events/${id}/speakers`,
        body: props,
      }),
      extraOptions: { multipart: true },
      invalidatesTags: ["Events", "form"],
    }),

    updateEventSpeaker: builder.mutation({
      query: ({ id, speakerID, props }) => ({
        method: "POST",
        url: `/events/${id}/speakers/update/${speakerID}`,
        body: props,
      }),
      providesTags: ["EventApi"],
    }),

    deleteEventSpeaker: builder.mutation({
      query: ({ eventID, speakerID }) => ({
        method: "DELETE",
        url: `/events/${eventID}/speakers/${speakerID}`,
      }),
      invalidatesTags: ["Events", "form"],
    }),

    addEventToCalendar: builder.mutation({
      query: (props) => ({
        method: "POST",
        url: `/calendar/events`,
        body: props,
      }),
      providesTags: ["joinEvent"],
    }),

    removeEventToCalendar: builder.mutation({
      query: (id) => ({
        method: "DELETE",
        url: `/calendar/events/${id}`,
      }),
      providesTags: ["joinEvent"],
    }),

    intersetEvent: builder.mutation({
      query: (props) => ({
        method: "POST",
        url: `/interested/events`,
        body: props,
      }),
      providesTags: ["joinEvent"],
    }),

    unIntersetEvent: builder.mutation({
      query: (id) => ({
        method: "DELETE",
        url: `/interested/events/${id}`,
      }),
      providesTags: ["joinEvent"],
    }),
  }),
});

export const {
  useEventCardQuery,

  useGetAllEventsQuery,
  useGetUserCalendarEventsQuery,
  useGetGroupEventsQuery,
  useGetSingleEventQuery,
  useGetTimeZonesQuery,

  useCreateEventMutation,
  useUpdateEventMutation,
  useDeleteEventMutation,

  useCreateEventOrganizerMutation,
  useUpdateEventOrganizerMutation,
  useDeleteEventOrganizerMutation,

  useCreateEventSpeakerMutation,
  useUpdateEventSpeakerMutation,
  useDeleteEventSpeakerMutation,

  useAddEventToCalendarMutation,
  useRemoveEventToCalendarMutation,
  useIntersetEventMutation,
  useUnIntersetEventMutation,

  useLazyGetAllEventsQuery,
} = EventApi;
