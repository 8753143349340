import { useNavigate } from "react-router-dom";
import Footer from "../../../Footer/Footer";
import Header from "../../../Header/header";
import EducationHeader from "../../Education/Educationcomponents/EducationHeader";
import FormJob from "./FormJob";
import { useAuth } from "../../../Context/authContext";
import { useEffect } from "react";

const CreationFormJob = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    if (user?.user_type === "HCP") {
      return navigate("/home");
    }
  }, [navigate, user?.user_type]);

  return (
    <>
      <Header />
      <EducationHeader title={"Add New Job"} />
      <FormJob />
      <Footer />
    </>
  );
};

export default CreationFormJob;
