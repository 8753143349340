import React, { useEffect, useState } from "react";
import "./footer.css";
import { Col, Container, ListGroup, ListGroupItem, Row } from "react-bootstrap";

// Import Image
import Logo from "../assets/images/logo.svg";
import Twitter from "../assets/images/Twitter.svg";
import Youtube from "../assets/images/Youtube.svg";
import Facebook from "../assets/images/Facebook.svg";
import LinkedIn from "../assets/images/Linkedin.svg";
import LiveBar from "./LiveBar";
import { Link } from "react-router-dom";

const FooterLinks = [
  {
    id: 1,
    title: "Medicaspace",
    Links: [
      {
        id: 1,
        title: "Contact us",
      },
      {
        id: 2,
        title: "About us",
      },
      {
        id: 3,
        title: "Support",
      },
      {
        id: 4,
        title: "Career",
      },
      {
        id: 5,
        title: "Partnership",
      },
    ],
    // path: "/",
  },

  {
    id: 2,
    title: "Know More",
    Links: [
      {
        id: 1,
        title: "Scoring points",
      },
      {
        id: 2,
        title: "Pricing",
        path : "/upgrade/premium"
      },
      {
        id: 3,
        title: "Business solution",
      },
      {
        id: 4,
        title: "Terms&Conditions",
      },
      {
        id: 5,
        title: "Premium",
      },
    ],
    // path: "/",
  },

  {
    id: 3,
    title: "Ask For",
    Links: [
      {
        id: 1,
        title: "Marketing campaign",
      },
      {
        id: 2,
        title: "Marketing Material",
      },
      {
        id: 3,
        title: "Live Streaming",
      },
      {
        id: 4,
        title: "Banners",
      },
      {
        id: 5,
        title: "Internship",
      },
    ],
    // path: "/",
  },
];
export default function Footer() {
  const [isVisible, setIsVisible] = useState(false);

  // Show button when user scrolls down 100px
  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 100) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  // Scroll to top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <footer className="footer">
        {isVisible && (
          <div className="arrowbtn" onClick={scrollToTop} title="Go to top">
            <i className="bi bi-arrow-up"></i>
          </div>
        )}

        <Container fluid>
          <Row className="gap-xl-4 w-full">
            <Col lg="5" className="border-b mb-4 sm:border-b-0">
              <Link to="/home">
                <img alt="" src={Logo} width="235" height="57" />
              </Link>
              <h4 className="w-full text-submain text-base font-title font-semibold mt-4">
                Medicaspace
              </h4>
              <p className="max-w-xs text-sm text-text  font-normal ">
                Dedicated Medical Space .
              </p>
            </Col>
            {FooterLinks?.map((link) => {
              return (
                <Col lg="2" key={link?.id}>
                  <h1 className="footer__link-title !text-main">{link?.title}</h1>

                  <ListGroup className="footer__quick-as">
                    {link?.Links?.map((Item) => {
                      return (
                        <Link
                          to={Item?.path ? Item?.path : "/coming-soon"}
                          className="no-underline hover:!text-main"
                          key={Item?.id}
                        >
                          <ListGroupItem
                            aria-label={Item?.title}
                            className="ps-0 border-0 hover:!text-main hover:!underline transition-all duration-300"
                          >
                            {Item?.title}
                          </ListGroupItem>
                        </Link>
                      );
                    })}
                  </ListGroup>
                </Col>
              );
            })}

            <Col lg="1" className="social__icons mb-3">
              <a
                href="https://x.com/OMedicaspace?t=98I1-qShvRILwZA0Kc0xdQ&s=08"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  alt=""
                  src={Twitter}
                  className="d-inline-block align-top"
                  width={"18"}
                />
              </a>

              <a
                href="https://www.youtube.com/@medicaspacededicatedmedica1576"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  alt=""
                  src={Youtube}
                  className="d-inline-block align-top"
                  width={"18"}
                />
              </a>

              <a
                href="https://www.facebook.com/Medicaspace.official?mibextid=LQQJ4d"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  alt=""
                  src={Facebook}
                  className="d-inline-block align-top facebook-icon"
                />
              </a>

              <a
                href="https://www.linkedin.com/company/medicaspace/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  alt=""
                  src={LinkedIn}
                  className="d-inline-block align-top"
                  width={"18"}
                />
              </a>
            </Col>
          </Row>
        </Container>
      </footer>
      <LiveBar />
    </>
  );
}
