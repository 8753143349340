import React from "react";
import { useNavigate } from "react-router-dom";
import { Indicator } from "@mantine/core";
import NotificationMenu from "../Chat/Notifications/NotificationMenu";
import UserMenu from "./UserMenu";
import { TfiMedall } from "react-icons/tfi";
import {useGetScoringPointsQuery} from '../store/api/ScoringPoints/ScoringPointsApi'

export default function AfterLogin({getUnreadComments}) {
  const navigate = useNavigate();
  const { data: getScoringPoints } = useGetScoringPointsQuery();
  
  return (
    <div className="hidden lg:flex items-center gap-4">
      {/* Scoring Points */}
      <Indicator
        inline
        label={getScoringPoints?.points}
        size={16}
        color="#012e54"
        position="top-end"
        className="cursor-pointer hover:bg-bgmain hover:rounded-full hover:w-auto p-2"
        onClick={() => navigate("/scoring-points")}
      >
        <TfiMedall className="text-main text-xl" />
      </Indicator>

      {/* Notification */}
      <NotificationMenu />

      {/* Messages */}
      <Indicator
        inline
        color="#012e54"
        size={14}
        label={getUnreadComments?.data?.unread_chats}
        messages
        position="top-end"
        className="cursor-pointer hover:bg-bgmain hover:rounded-full hover:w-auto p-1"
        onClick={() => navigate("/messages")}
      >
        <i className="bi bi-chat-dots-fill text-main text-xl"></i>
      </Indicator>

      {/* User */}
      <UserMenu />
    </div>
  );
}
